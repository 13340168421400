import React, { useContext } from "react"
import { graphql } from "gatsby"

import ArticlesComponent from "../components/articles"
import Layout from "../components/layout"
import LanguageContext from "../wrappers/contexts/LanguageContext";

export const query = graphql`  
  query Category($id: String) {
    articles: allStrapiArticle(filter: { category: { id: { eq: $id } } }) {
      edges {
        node {
        id
          strapiId
          published_at
          title {
            translate {
             text
             language {
              lang
             }
            }
          }
          category {
            name {
              translate {
               text
               language {
                lang
               }
              }
            }
          }
          image {
            publicURL
          }
        }
      }
    }
    category: strapiCategory(strapiId: { eq: $id }) {
      name {
       translate {
               text
               language {
                lang
               }
              }
            }
    }
  }
`;

const Category = ({ data }) => {
  const articles = data.articles.edges;
  const category = data.category;

  return (
    <Layout seo={{ title: category.name.translate }}>
      <InsideTheLanguageWrapper articles={articles} category={category}/>
    </Layout>
  )
};

const InsideTheLanguageWrapper = ({ articles, category }) => {
  const { getTranslate } = useContext(LanguageContext);
  return (
    <div className="uk-section uk-section-large">
      <div className="uk-container uk-container-large">
        <h1 className="title-category">{getTranslate(category.name.translate)}</h1>
        <ArticlesComponent articles={articles}/>
      </div>
    </div>
  )
};

export default Category
